import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gradient-to-b from-[#294C2D] to-[#61B26A] text-white py-8 w-full h-full md:h-[325px] p-[30px] md:p-[50px]" id='section3'>
            <div className='flex flex-col gap-[30px] md:gap-[50px]'>
            <div className="w-full mt-6 mx-auto px-4">
                <div className="">
                    <div className="flex items-center justify-center text-center">
                        <p className="text-[18px] leading-[19px] text-[rgba(255, 255, 255, 0.6)]">
                            Your Go-To Platform for QR Codes, Pass Codes & URL Shortening
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap gap-[40px] justify-around md:justify-between'>
                <div>
                    <h4 className="font-bold mb-4 text-center text-[22px] leading-[24px] ">Products</h4>
                    <ul className="space-y-2">
                        <li><a href="#" className="hover:underline text-[22px] leading-[24px] font-medium ">QRCode.Generator4u.com</a></li>
                        <li><a href="#" className="hover:underline text-[22px] leading-[24px] font-medium">Passcode.Generator4u.com</a></li>
                        <li><a href="#" className="hover:underline text-[22px] leading-[24px] font-medium">Shorturl.Generator4u.com</a></li>
                    </ul>
                </div>

                <div className="text-right">
                    <h4 className="font-bold mb-4 text-start text-[22px] leading-[24px]  ">Blogs</h4>
                    <ul className="space-y-2">
                        <li><a href="#" className="hover:underline text-[22px] leading-[24px] font-medium ">Contact Us</a></li>
                    </ul>
                </div>

            </div>
            </div>
        </footer>
    );
};

export default Footer;
