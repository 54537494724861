import React from 'react'

function Landing() {
  return (
    <div  id='section1' className=' '>
       <div className=" flex  justify-between">
      <div className="flex flex-wrap gap-[40px] justify-around items-end w-full ">
        <div className="text-white px-[20px] ">
         <div className='w-full md:w-[550px]'>
         <h1 className="text-3xl md:text-6xl font-bold leading-[52px] md:leading-[72px]">     
            The Best Place to Generate
          </h1>
          <h2 className="text-[#D69FFF] text-[36px] sm:text-[46px] md:text-[66px] leading-[52px] md:leading-[72px] font-semibold">
            QR Code. <br />
            Pass Codes. <br />
            Short URL.
          </h2>
          <p className="text-[14px] md:text-[16px] text-[#FFFFFF75]">
            Create QR Codes, Generate Pass Codes, and Shorten URLs with Ease
          </p>
         </div>
          <p className="mt-10 text-[18px] md:text-[20px] text-[#FFFFFF] w-full md:w-[500px] leading-[21px]">
            Generate custom QR codes, secure pass codes, and shorten URLs
            instantly with our all-in-one online tool. Fast, reliable, and
            perfect for businesses and personal use!
          </p>
        </div>

        {/* Right Section - Image */}
        <div className=" ">
          <div className="bg-gradient-to-b from-white to-[#202020]-200 rounded-[40px] w-[310px] sm:w-[400px] md:w-[460px] h-[500px] sm:h-[650px] flex items-center justify-center relative">
            <img
              src="/assets/svg/image1.svg" // Replace with the actual image URL
              alt="Man Pointing"
              className="rounded-lg object-cover absolute left-0 bottom-0"
            />
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Landing
