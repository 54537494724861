import React, { useState } from 'react';
import Scrollspy from 'react-scrollspy';
import Landing from '../landing';
import CardSection from './cardsection';
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const list = [
    { text: 'Home', sec: '#section1' },
    { text: 'Products', sec: '#section2' },
    { text: 'Contact', sec: '#section3' },
    { text: 'About', sec: '#section3' }
  ];
  return (
   <div>
     <nav className="py-4 px-[20px] md:px-[70px] mb-[60px]">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-[18px] font-bold text-[#fff]">Generator4u</div>

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-6">
        <Scrollspy
            items={['section1', 'section2', 'section3']}
            currentClassName="active"
          >
          <div className='flex gap-4'>
            {list.map((item, index) => (
              <a key={index} href={item.sec} className='text-[#fff] text-[18px] font-bold leading-[20px]'>{item.text}</a>
            ))}
          </div>
          </Scrollspy>
        </div>

        {/* Hamburger Menu (Mobile) */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div
          className="md:hidden fixed top-[40px] right-0 w-[300px] h-[250px] bg-black bg-opacity-80 rounded-[30px] z-[99999] flex flex-col items-center justify-center"
        >
          <Scrollspy
            items={['section1', 'section2', 'section3']}
            currentClassName="active"
          >
            <div className="flex flex-col gap-4 w-full justify-center  text-center">
              {list.map((item, index) => (
                <a key={index} href={item.sec}  onClick={() => setIsOpen(!isOpen)} className="text-[#fff] p-2 text-[20px] font-bold cursor-pointer leading-[24px] hover:bg-gradient-to-r from-[#202020] to-[#59875E]">{item.text}</a>
              ))}
            </div>
          </Scrollspy>

        </div>
      )}
    </nav>
    <Landing/>
    <CardSection/>
   </div>
  );
};

export default Header;
