import React from 'react';

const cardData = [
  {
    title: 'QR Code Generator4u',
    description: 'Instantly create custom QR codes, secure pass codes, and shorten URLs with ease. Perfect for businesses and everyday users looking for fast, reliable code solutions!',
    image: '/assets/svg/cardimg1.svg', // Replace with your actual image URL
    buttonText: 'Explore',
    buttonLink: 'https://qrcode.generator4u.com/',
  },
  {
    title: 'Password Generator4u',
    description: 'Create strong, secure passwords instantly with our easy-to-use online tool. Protect your accounts with reliable, randomized passcodes for ultimate security!',
    image: '/assets/svg/carding2.svg', // Replace with your actual image URL
    buttonText: 'Explore',
    buttonLink: 'https://passcode.generator4u.com/',
  },
  {
    title: 'Shorten URL Generator4u',
    description: 'Instantly create custom QR codes, secure pass codes, and shorten URLs with ease. Perfect for businesses and everyday users looking for fast, reliable code solutions!',
    image: '/assets/svg/cardimg3.svg', // Replace with your actual image URL
    buttonText: 'Launching Soon',
    buttonLink: '',
  },
];

const CardSection = () => {
  return (
    <div className=" min-h-screen py-12 mb-[40px] md:mb-[90px] " id='section2'>
      <div className="w-full mx-auto">
        {/* Grid container for the cards */}
        <div className="flex flex-wrap gap-8 justify-center items-center">

          {/* Map through the cardData array to create each card */}
          {cardData.map((card, index) => (
            <div>
              <div key={index} className="bg-gradient-to-b from-[#294C2D] to-[#61B26A] rounded-[30px] shadow-lg w-full sm:w-[400px] h-[500px] sm:h-[600px] relative text-center">
                <div className='mx-w-[400px] p-5 items-center absolute top-[38px]'>
                  <h3 className="text-xl font-bold text-[#FFFFFF] mb-4 leading-[24px]">{card.title}</h3>
                  <p className="text-sm text-[#FFFFFF] mb-6 leading-[21px]">{card.description}</p>
                </div>
                <div className="flex justify-center absolute bottom-[-50px] sm:bottom-0">
                  <img src={card.image} alt={card.title} className="w-[400px] h-[377px] rounded-[30px]  " />
                </div>
              </div>
              <div className='flex justify-center items-center mt-6'>
                <button
                  className={`bg-gradient-to-r from-[#294C2D] to-[#61B26A] text-white text-[22px] leading-[24px] font-semibold  w-[293px] h-[65px] rounded-[30px]`}
                  onClick={() => window.open(card.buttonLink, '_blank')}  >
                  {card.buttonText}
                </button>
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
};

export default CardSection;
