import './App.css';
import Footer from './pages/components/footer';
import Header from './pages/components/header';
import Landing from './pages/landing';

function App() {
  return (
    <div className='bg-gradient-to-r from-[#202020] to-[#59875E] min-h-screen'>
      <div className='mx-[30px] md:mx-[80px]'>
      <Header/>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
